import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import openSocket from 'socket.io-client';
import {
  VEND_REDUCER,
  MACHINE,
  VEND_STATUS,
  TIMER,
  REQUEST_REDUCER_TYPES,
  MODAL_REDUCER,
  MACHINE_STATUS,
  CASH_RETURNER_STAGE,
} from '../../actions/constants';
import { ThreeBubbleLoader, LoaderCircular } from '../../components';
import { vendNow } from '../../actions/mqtt';
import { getRequestItems, updateRequestVendStatus, updateRequestItemVendStatus } from '../../actions/db';
import './style.css';
import OrderComplete from './OrderComplete';
import VendingUI from '../../components/VendModalUI/vending';
import TickAnimationAudio from '../../components/TickAnimationAudio';
import VendingCardDetailsUI from '../../components/VendModalUI/showVendingCardDetails';
import VendingCardDetailsNewUI from '../../components/VendModalUI/showVendingCardDetailsNewUI';

function getLinearCartItems(cartItems, requestId) {
  let count = 1;
  const linearItems = [];
  cartItems.forEach(item => {
    for (let i = 0; i < item.quantity; i += 1) {
      const requestItemId = `${requestId}_${count}`;

      const requestItemData = {
        bill_item_id: requestItemId,
        position: item.pos,
      };
      linearItems.push(requestItemData);
      count += 1;
    }
  });
  return linearItems;
}

class VendModal extends React.Component {
  constructor(props) {
    super(props);
    const { paymentMethodUsed, totalAmount } = { ...this.props };
    this.state = {
      currentVendedItems: [], // Items vended according to machine
      paymentMethodUsed,
      vendNotAutherized: false,
      billedAmount: totalAmount,
    };

    this.vend = this.vend.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCoinChangerInitTimeOut = this.handleCoinChangerInitTimeOut.bind(this);
    this.startItemVendTimeout = this.startItemVendTimeout.bind(this);
  }

  componentDidMount() {
    const { resetCart } = { ...this.props };
    this.vend();
    resetCart();
  }

  componentDidUpdate(prevProps) {
    const { isOrderPicked, vendStatus, currentVendingItemIndex, itemsToVend } = { ...this.props };

    if (vendStatus !== VEND_STATUS.INITIATING) {
      window.customClearSetTimeout(this.vendInitTimeout, TIMER.VENDING_TIME_OUT_30_SEC);
      if (prevProps.vendStatus === VEND_STATUS.INITIATING && vendStatus === VEND_STATUS.VENDING) {
        this.startItemVendTimeout();
      }
    }

    if (prevProps.currentVendingItemIndex < currentVendingItemIndex && vendStatus === VEND_STATUS.VENDING) {
      window.customClearSetTimeout(this.vendItemTimeout, TIMER.VENDING_INTERVAL_TIMER_20_SEC);
      if (currentVendingItemIndex < itemsToVend.length) {
        this.startItemVendTimeout();
      }
    }

    if (!prevProps.isOrderPicked && isOrderPicked) {
      // console.log('Prev isOrderPicked ', prevProps.isOrderPicked);
      // console.log('Current isOrderPicked ', isOrderPicked);
      this.closeModal();
    }
  }

  componentWillUnmount() {
    try {
      this.socket.disconnect();
    } catch (e) {
      // console.log(e);
    }
    window.customClearSetTimeout(this.vendItemTimeout, TIMER.VENDING_INTERVAL_TIMER_20_SEC);
    window.customClearSetTimeout(this.vendInitTimeout, TIMER.VENDING_TIME_OUT_30_SEC);
  }

  handleNoResponse = () => {
    const { updateVendStatus } = { ...this.props };
    updateVendStatus({
      type: VEND_REDUCER.STATUS_BROKEN,
      data: {},
    });
  };

  initiateOrder = () => {
    const { requestId, itemsToVend, updateVendStatus } = { ...this.props };
    let payload = {
      oid: requestId,
    };

    this.socket.emit('ORDER_INIT', payload);
    this.socket.on('ORDER_INIT_RESPONSE', data => {
      // console.log('ORDER_INIT_RESPONSE: ', data);

      window.customClearSetTimeout(this.vendInitTimeout, TIMER.VENDING_TIME_OUT_30_SEC);
      if (!data.e) {
        updateRequestVendStatus(requestId, 'started');
        updateVendStatus({ type: VEND_REDUCER.STATUS_UPDATE, data: { status: VEND_STATUS.VENDING } });
        payload = {
          oiid: itemsToVend[0].bill_item_id,
          position: itemsToVend[0].position,
        };

        this.socket.emit('VEND_ORDER_ITEM', payload);
      } else {
        updateRequestVendStatus(requestId, 'complete failure');
        this.handleNoResponse();
      }
    });
    this.socket.on('VEND_ORDER_ITEM_RESPONSE', data => {
      const { currentVendingItemIndex } = { ...this.props };
      // console.log('VEND_ORDER_ITEM_RESPONSE: ', data, currentVendingItemIndex);
      // Save the vend response.

      const parsedData = JSON.parse(data);
      if (parsedData.oiid === itemsToVend[currentVendingItemIndex].bill_item_id) {
        const resp = {
          isSuccess: parsedData.s,
          itemId: parsedData.oiid,
          pos: parsedData.items,
        };
        const vendStatus = parsedData.s ? 'success' : 'failed';
        updateRequestItemVendStatus(itemsToVend[currentVendingItemIndex].bill_item_id, vendStatus, parsedData.oir);
        updateVendStatus({
          type: VEND_REDUCER.UPDATE,
          data: resp,
        });
      }
      if (currentVendingItemIndex === itemsToVend.length - 1) {
        // Send Vending Complete
        updateRequestVendStatus(requestId, 'completed');
        updateVendStatus({
          type: VEND_REDUCER.STATUS_UPDATE,
          data: { status: VEND_STATUS.VEND_COMPLETE },
        });
        this.socket.disconnect();
      } else {
        payload = {
          oiid: itemsToVend[currentVendingItemIndex + 1].bill_item_id,
          position: itemsToVend[currentVendingItemIndex + 1].position,
        };
        this.socket.emit('VEND_ORDER_ITEM', payload);
      }
    });
  };

  vend() {
    const { requestId, updateVendStatus, updateItemsToVend, vendModalUIOptions, machineStatus, cartItems } = {
      ...this.props,
    };

    updateVendStatus({ type: VEND_REDUCER.STATUS_UPDATE, data: { status: VEND_STATUS.INITIATING } });
    if (machineStatus === MACHINE_STATUS.ONLINE) {
      // Start Vending. Call vendNow in case of MQTT System.
      vendNow({ requestId }).then(data => {
        if (!data.error) {
          // Update Items to Vend

          this.setState({
            vendNotAutherized: false,
          });
          updateItemsToVend(data.data.items);
        } else {
          // Broken. Wrong data
          this.setState({
            vendNotAutherized: true,
          });
          updateVendStatus({ type: VEND_REDUCER.STATUS_UPDATE, data: { status: VEND_STATUS.BROKEN } });
        }
      });
    } else if (machineStatus === MACHINE_STATUS.OFFLINE) {
      // Use Sockets to communicate
      const { socketURL } = { ...this.props };
      const url = socketURL || 'http://localhost:3000';
      this.socket = openSocket(url);
      this.socket.on('welcome', data => {
        // console.log('SOCKET WELCOME: ', data);
        // Fetch Items from local DB corresponding to requestId
        if (data.e) {
          updateRequestVendStatus(requestId, 'failure');
          this.handleNoResponse();
        } else {
          this.socket.emit('IDENTIFY', { user: 'KIOSK' });
          let vendItems = [];
          getRequestItems(requestId)
            .then(items => {
              if (items.length > 0) {
                vendItems = items.map(item => ({ position: item.position, bill_item_id: item.requestItemId }));
              } else {
                vendItems = getLinearCartItems(cartItems, requestId);
              }
              updateItemsToVend(vendItems);
              this.initiateOrder();
            })
            .catch(() => {
              // Falling back
              vendItems = getLinearCartItems(cartItems, requestId);
              updateItemsToVend(vendItems);
              this.initiateOrder();
            });
        }
      });
    }

    this.vendInitTimeout = window.customSetTimeOut(
      () => this.handleNoResponse(),
      1000 * vendModalUIOptions.vendInitMaxTime,
      TIMER.VENDING_TIME_OUT_30_SEC,
    );
  }

  closeModal() {
    const {
      vendStatus,
      isOrderPicked,
      vendModalUIOptions,
      vendedItems,
      totalItemsFailed,
      setRequestId,
      closeVendModal,
      checkUpdate,
      kioskUpdate,
      refreshMachine,
      setKioskUpdate,
    } = this.props;
    const { vendNotAutherized } = { ...this.state };
    const isVendingComplete = vendStatus === VEND_STATUS.VEND_COMPLETE;
    const hasAnyItemVended = totalItemsFailed !== vendedItems.length && vendedItems.length !== 0;
    const allowedOrderBeforeCollection = vendModalUIOptions.allowOrderBeforeCollection;
    const hasUserPickedVendedItem = (hasAnyItemVended && isOrderPicked) || !hasAnyItemVended;

    if (
      vendNotAutherized ||
      (isVendingComplete &&
        (allowedOrderBeforeCollection || (!allowedOrderBeforeCollection && hasUserPickedVendedItem)))
    ) {
      setRequestId(null);
      checkUpdate();
      closeVendModal(false);
    }

    if (kioskUpdate) {
      refreshMachine();
      setKioskUpdate(false);
    }
  }

  // componentDidCatch(err, info) {
  //   Console.log(err);
  //   Console.log(info);
  // }

  handleCoinChangerInitTimeOut() {
    const { cashReturnerStage, setCashReturnerBroken, openPushRemainderToWallet } = this.props;

    if (cashReturnerStage === CASH_RETURNER_STAGE.INIT) {
      // console.log('CASH RETURNER BROKEN');
      setCashReturnerBroken();
      openPushRemainderToWallet();
    }
  }

  startItemVendTimeout() {
    const { itemVendingFailed, updateVendStatus, vendModalUIOptions } = { ...this.props };
    window.heap.track('Vending interval', {
      time: Date.now(),
    });

    this.vendItemTimeout = window.customSetTimeOut(
      () => {
        const { itemsToVend, currentVendingItemIndex, machineStatus } = { ...this.props };
        if (machineStatus === MACHINE_STATUS.OFFLINE) {
          updateRequestItemVendStatus(
            itemsToVend[currentVendingItemIndex].bill_item_id,
            'failed',
            'NO RESPONSE FROM SOCKET',
          );
        }
        itemVendingFailed({
          isSuccess: false,
          itemId: itemsToVend[currentVendingItemIndex].bill_item_id,
          pos: itemsToVend[currentVendingItemIndex].position,
        });
        if (currentVendingItemIndex === itemsToVend.length - 1) {
          // Send Vending Complete
          updateVendStatus({
            type: VEND_REDUCER.STATUS_UPDATE,
            data: { status: VEND_STATUS.VEND_COMPLETE },
          });
        } else if (machineStatus === MACHINE_STATUS.OFFLINE) {
          // Vend next item
          const payload = {
            oiid: itemsToVend[currentVendingItemIndex + 1].bill_item_id,
            position: itemsToVend[currentVendingItemIndex + 1].position,
          };
          this.socket.emit('VEND_ORDER_ITEM', payload);
        }
      },
      1000 * vendModalUIOptions.vendItemMaxTime,
      TIMER.VENDING_INTERVAL_TIMER_20_SEC,
    );
  }

  render() {
    const {
      isOpenVendModal,
      vendedItems,
      vendStatus,
      itemsToVend,
      requestId,
      totalAmountUpdated,
      totalItemsFailed,
      maxItems,
      vendModalUIOptions,
      defaultProductURL,
      modalSize,
      vendModalLanguage,
      customDisplayMessage,
      machineType,
      rfidPaymentUserDetails,
      showRfidUserDetails,
      closeVendModal,
    } = this.props;
    const { paymentMethodUsed, billedAmount, vendNotAutherized } = { ...this.state };
    const currentIndex = vendedItems.length;
    const {
      btnOrderNotAllowedMsg,
      btnOrderNotAllowedInstruction,
      txtExtraAmountSwiftMsg,
      txtExtraAmountAfterVend,
      txtCurrentVendingInfo,
      btnVendNotAuthClose,
      txtVendingItemsTitle,
      txtPaymentCompleteTitle,
      txtOrderFailedTitle,
      txtOrderCompleteTitle,
    } = vendModalLanguage;

    const totalItemsToVend = itemsToVend.length;
    let currentItem;
    if (totalItemsToVend > 0) {
      currentItem = itemsToVend[currentIndex];
      // console.log('itemsToVend', itemsToVend);
      // console.log('currentItem', currentItem);
    }

    let balanceDueToFailItems = 0;

    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    vendedItems.forEach(({ isSuccess, pos }) => {
      if (!isSuccess) {
        balanceDueToFailItems += itemsToVend.find(product => {
          return product.position === pos;
        }).price;
      }
    });

    let headerText;
    if (vendStatus === VEND_STATUS.VENDING) {
      headerText = txtVendingItemsTitle;
    } else if (vendStatus === VEND_STATUS.INITIATING) {
      headerText = txtPaymentCompleteTitle;
    } else if (vendStatus === VEND_STATUS.VEND_COMPLETE) {
      if (vendedItems.length === 0 || totalItemsFailed !== 0) {
        headerText = txtOrderFailedTitle;
      } else {
        headerText = txtOrderCompleteTitle;
      }
    }

    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));
    // console.log('totalAmountUpdated - billedAmount', totalAmountUpdated - billedAmount);
    return (
      <Modal isOpen={isOpenVendModal} size={modalSize || 'md'}>
        {vendStatus === VEND_STATUS.INITIATING && <TickAnimationAudio />}
        <ModalBody>
          <div>
            {vendStatus === VEND_STATUS.VENDING && vendModalUIOptions.showVendingItemDetails && currentItem && (
              <VendingUI defaultProductURL={defaultProductURL} currentItem={currentItem} />
            )}
          </div>
          <div>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h1 className="text-red ">{headerText}</h1>
                {vendStatus !== VEND_STATUS.VENDING && vendStatus !== VEND_STATUS.INITIATING && (
                  <button
                    onClick={() => closeVendModal(true)}
                    type="button"
                    className="close"
                    style={{ padding: '16px', position: 'absolute', top: '0', right: '0' }}
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                )}
                {vendNotAutherized && (
                  <div style={{ padding: '10px' }}>
                    <Button
                      data-test="reset-cart-button"
                      className="full-length-btn wendor-bg-red"
                      style={{ height: '80px' }}>
                      {btnOrderNotAllowedMsg}
                    </Button>
                    <h4>{btnOrderNotAllowedInstruction} </h4>
                  </div>
                )}
                {/* Hidden for future use, can be used to show custom messages */}
                {false && paymentMethodUsed === 'prasuk' && customDisplayMessage.length > 0 && (
                  <p className="padding-10">{customDisplayMessage}</p>
                )}
                {vendModalUIOptions.showOrderId && <p>Order Id: {requestId}</p>}
                {paymentMethodUsed === 'cash' &&
                  cashPhoneOption !== 'coin_returner_with_phone_after_payment' &&
                  cashPhoneOption !== 'currency_return_after_payment' &&
                  totalAmountUpdated - billedAmount > 0 && (
                    <p className="padding-10">
                      <strong>
                        {currencyDetails.isSuffix ? null : currencyDetails.symbol}
                        {totalAmountUpdated - billedAmount + balanceDueToFailItems}{' '}
                        {currencyDetails.isSuffix ? currencyDetails.symbol : null}
                      </strong>{' '}
                      {txtExtraAmountSwiftMsg}
                    </p>
                  )}
                {paymentMethodUsed === 'rfid' &&
                  rfidPaymentUserDetails !== null &&
                  rfidPaymentUserDetails !== undefined &&
                  showRfidUserDetails && (
                    <p className="padding-10">
                      <strong>
                        Rfid Id: {rfidPaymentUserDetails.rfid_id}
                        <br />
                        Updated Balance: {rfidPaymentUserDetails.updated_balance}
                      </strong>{' '}
                    </p>
                  )}
                {paymentMethodUsed === 'cash' &&
                  cashPhoneOption === 'currency_return_after_payment' &&
                  totalAmountUpdated - billedAmount > 0 && (
                    <h3 className="h3adding-10">
                      {txtExtraAmountAfterVend}{' '}
                      <strong>
                        {currencyDetails.isSuffix ? null : currencyDetails.symbol}
                        {totalAmountUpdated - billedAmount + balanceDueToFailItems}
                        {currencyDetails.isSuffix ? currencyDetails.symbol : null}
                      </strong>
                    </h3>
                  )}
                {paymentMethodUsed === 'cash' &&
                  cashPhoneOption === 'coin_returner_with_phone_after_payment' &&
                  totalAmountUpdated - billedAmount > 0 &&
                  totalItemsFailed === 0 && (
                    <h3 className="h3adding-10">
                      {txtExtraAmountAfterVend}{' '}
                      <strong>
                        {currencyDetails.isSuffix ? null : currencyDetails.symbol}
                        {totalAmountUpdated - billedAmount + balanceDueToFailItems}
                        {currencyDetails.isSuffix ? currencyDetails.symbol : null}
                      </strong>
                    </h3>
                  )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                {vendStatus === VEND_STATUS.INITIATING && <ThreeBubbleLoader />}
                {vendStatus === VEND_STATUS.VEND_COMPLETE && (
                  <OrderComplete
                    closeModal={this.closeModal}
                    vendedItems={vendedItems}
                    totalItemsFailed={totalItemsFailed}
                    paymentMethodUsed={paymentMethodUsed}
                    requestId={requestId}
                    maxItems={maxItems}
                    isEnteredAmountMore={totalAmountUpdated - billedAmount > 0}
                    handleCoinChangerInitTimeOut={this.handleCoinChangerInitTimeOut}
                    allowOrderBeforeCollection={vendModalUIOptions.allowOrderBeforeCollection}
                  />
                )}
              </Col>
            </Row>
            {maxItems > 1 && (
              <div>
                {currentIndex < itemsToVend.length && vendStatus === VEND_STATUS.VENDING && (
                  <span>
                    {txtCurrentVendingInfo} {itemsToVend[currentIndex].position} {itemsToVend[currentIndex].name} [
                    {currentIndex + 1}/{itemsToVend.length}]
                  </span>
                )}
              </div>
            )}
            {maxItems === 1 && vendStatus === VEND_STATUS.VENDING && <LoaderCircular />}
            {!vendNotAutherized &&
              vendModalUIOptions.showVendingCardDetails &&
              machineType !== 'newuiwithoutphoto' &&
              machineType !== 'newuiwithphoto' &&
              machineType !== 'newuiwithphotodubai' &&
              machineType !== 'newuiwithoutphotowithoutscroll' && (
                <VendingCardDetailsUI
                  // txtMoneySymbol={txtMoneySymbol}
                  itemsToVend={itemsToVend}
                  vendedItems={vendedItems}
                  vendModalUIOptions={vendModalUIOptions}
                />
              )}
            {!vendNotAutherized &&
              vendModalUIOptions.showVendingCardDetails &&
              (machineType === 'newuiwithoutphoto' ||
                machineType === 'newuiwithphoto' ||
                machineType === 'newuiwithphotodubai' ||
                machineType === 'newuiwithoutphotowithoutscroll') && (
                <VendingCardDetailsNewUI
                  // txtMoneySymbol={txtMoneySymbol}
                  itemsToVend={itemsToVend}
                  vendedItems={vendedItems}
                  vendModalUIOptions={vendModalUIOptions}
                />
              )}
          </div>
          {vendNotAutherized && (
            <Button
              data-test="pay-button"
              className="wendor-big-button wendor-bg-green"
              onClick={() => this.closeModal()}>
              {btnVendNotAuthClose}
            </Button>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  machine,
  cashReducer,
  modalReducer,
  cartReducer,
  requestReducer,
  vendReducer,
  languageReducer,
  currencyReducer,
  rfidModule,
}) => ({
  isOpenVendModal: modalReducer.isOpenVendModal,
  machineStatus: machine.machineStatus,
  kioskUpdate: machine.kioskUpdate,

  machineType: machine.machineType,
  socketURL: machine.socketURL,
  vendModalUIOptions: machine.vendModal,
  maxItems: machine.machineDetails.maxItems,
  modalSize: machine.machineDetails.modalSize,
  defaultProductURL: machine.machineDetails.defaultProductURL,
  requestId: requestReducer.requestId,
  paymentStatus: requestReducer.paymentStatus,
  paymentMethodUsed: requestReducer.paymentMethodUsed,
  totalAmountUpdated: requestReducer.totalAmountUpdated,
  totalAmount: cartReducer.totalAmount,
  cartItems: cartReducer.cartData,
  vendStatus: vendReducer.vendStatus,
  vendedItems: vendReducer.vendedItems,
  itemsToVend: vendReducer.itemsToVend,
  totalItemsFailed: vendReducer.totalItemsFailed,
  currentVendingItemIndex: vendReducer.currentVendingItemIndex,
  customDisplayMessage: vendReducer.customDisplayMessage,
  mqttStatus: machine.mqttStatus,
  isOrderPicked: vendReducer.isOrderPicked,
  cashReturnerStage: cashReducer.cashReturnerStage,
  currencyReturnerStage: currencyReducer.currencyReturnerStage,
  vendModalLanguage: languageReducer.vendModalLanguage,
  rfidPaymentUserDetails: rfidModule.userDetails,
  showRfidUserDetails: machine.rfidModule.showRfidUserDetails,
});

const mapDispatchToProps = dispatch => ({
  setCashReturnerBroken: () => dispatch({ type: CASH_RETURNER_STAGE.SET_STATUS, payload: CASH_RETURNER_STAGE.BROKEN }),
  setKioskUpdate: payload => dispatch({ type: MACHINE.SET_KIOSK_UPDATE, payload }),
  refreshMachine: () => dispatch({ type: MACHINE.REFRESH_MACHINE }),
  updateVendStatus: payload => dispatch({ type: payload.type, payload: payload.data }),
  updateItemsToVend: payload => dispatch({ type: VEND_REDUCER.ITEMS_TO_VEND, payload }),
  setRequestId: payload => dispatch({ type: REQUEST_REDUCER_TYPES.SET_REQUEST_ID, payload }),
  closeVendModal: () => dispatch({ type: MODAL_REDUCER.OPEN_VEND_MODAL, payload: false }),
  itemVendingFailed: payload => dispatch({ type: VEND_REDUCER.UPDATE, payload }),
  openPushRemainderToWallet: () => dispatch({ type: MODAL_REDUCER.OPEN_PUSH_REMAINDER_TO_WALLET_MODAL, payload: true }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendModal);

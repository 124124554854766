import React from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Button, Alert } from 'reactstrap';
import {
  BigMachineUI,
  SmallMachineUI,
  MetroTicketVending,
  NewUiWithoutPhoto,
  NewUiWithoutPhotoV2,
  NewUiWithPhoto,
  NewUiWithPhotoDubai,
  NewUiWithoutPhotoWithoutScroll,
  Footer,
} from '../../components';
import { getMachineData, updateMachineStatus } from '../../actions/machine';
import checkUpdate from '../../actions/checkUpdate';
import Payment from '../PaymentModal';
import Error from '../Error';
import VendModal from '../VendModal';
import TimedOutModal from '../PaymentModal/TimedOutModal';
import {
  MODAL_REDUCER,
  CART_REDUCER,
  LOG,
  MACHINE_STATUS,
  MACHINE,
  REQUEST_REDUCER_TYPES,
} from '../../actions/constants';
import { refillStateCheck } from '../../actions/refill';
import { checkMultivac } from '../../actions/mqtt';
import PhoneNumberInput from '../KeypadInput/PhoneNumberInput';

class ProductsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mSwipeConnected: false, // If mswipe is connected o{r not
      selectedProductIndex: 0, // For small machine
      errorInComponent: false,
      isProductLoading: false,
      errorInLoadingProduct: false,
      isErrorInProduct: false,
      isPopoverOpen: false,
      showPopoverForCartFull: false,
      className: '',
      buttonType: '',

      timeout: 30000, // 10 minutes in milliseconds
      remainingTime: null,
      hasTimedOut: false,
    };
    const { resetModals } = { ...this.props };
    resetModals(); // Reset all modals on start of this component
    this.idleTimer = null;

    this.onIdle = this.onIdle.bind(this);
    this.handleCloseCallback = this.handleCloseCallback.bind(this);

    [
      'checkout', // Checkout for payment. Pay now.
      'updateCart', // Add or remove item from cart.
      'unsetPingInterval', // Unset ping Interval
      'changeSelectedProduct', // Change selected product index for small machine
      'handleResetCart',
      'checkRefillRequest',
      'getSetProducts',
      'checkVersionUpdate',
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    const { resetCart, setProductsWithCartQuantity, isScreenSaverActive, productsData, productDisplayPageUIOptions } = {
      ...this.props,
    };
    resetCart(); // Reset cart
    // Get Machine Data

    if (!isScreenSaverActive || !productsData) {
      setProductsWithCartQuantity([]);
      this.getSetProducts(true);
    }

    if (productDisplayPageUIOptions.backgroundImage !== '') {
      document.body.style.backgroundColor = productDisplayPageUIOptions.backgroundColor;
      document.body.style.background = `#999 url(/assets/${productDisplayPageUIOptions.backgroundImage}) no-repeat fixed 50%`;
      document.body.style.backgroundSize = 'cover';
    } else {
      document.body.style.background = `none`;
      document.body.style.backgroundColor = productDisplayPageUIOptions.backgroundColor;
    }

    // this.startScreenSaverTimmer();
  }

  shouldComponentUpdate(nextProps) {
    const { isOpenVendModal, resetRequestData } = { ...this.props };
    const { showPopoverForCartFull } = { ...this.state };
    if (isOpenVendModal && !nextProps.isOpenVendModal) {
      if (showPopoverForCartFull) {
        this.setState({
          showPopoverForCartFull: false,
        });
      }
      resetRequestData();
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    // isOpenVendModal
    const { isOpenVendModal } = { ...this.props };
    if (!isOpenVendModal && prevProps.isOpenVendModal) {
      this.getSetProducts(false);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onIdle() {
    const { kioskUpdate, refreshMachine, setKioskUpdate } = { ...this.props };

    this.setState({ hasTimedOut: true });
    this.handleResetCart();

    if (kioskUpdate) {
      refreshMachine();
      setKioskUpdate(false);
    }
  }

  getSetProducts(showIsLoading) {
    // console.log('updating products');

    const { setProductsWithCartQuantity } = {
      ...this.props,
    };

    this.setState({ isProductLoading: showIsLoading });
    getMachineData()
      .then(data => {
        // Get Machine Products

        if (!data.error) {
          // Modify the products array to add cart quantity in it. As it will make it easy to keep track of products in cart
          // Make a new variable and keep fetched data also. Do not modify the fetched object structure
          const productsRaw = data.data;
          for (let i = 0; i < productsRaw.length; i += 1) {
            if (productsRaw[i].left_units < 0) {
              productsRaw[i].left_units = 0;
            }
          }
          const sortedProducts = productsRaw.sort(this.sortingAlgo);
          // console.log('sortedProducts', sortedProducts);
          // if(machineType === "rowProductSelect"){
          //   this.combineProductsOfSameRow()
          // }

          const productsWithCartQuantity = sortedProducts || [];

          this.setState({
            isProductLoading: false,
            isErrorInProduct: false,
          });
          setProductsWithCartQuantity(productsWithCartQuantity);
          // this.forceUpdate();
        } else {
          // If there is an error in fetching products
          this.setState({
            isProductLoading: false,
            isErrorInProduct: true,
          });
          setProductsWithCartQuantity([]);
        }
      })
      .catch(() => {
        // console.log(e);
        setProductsWithCartQuantity([]);
        this.setState({
          isProductLoading: false,
          isErrorInProduct: true,
        });
      });
  }

  handleClickAnimation = type => {
    this.setState({ buttonType: type });
    this.setState({ className: 'touch-animation-small ' });
    this.timer = setTimeout(() => {
      this.setState({ className: '' });
    }, 185);
  };

  sortingAlgo = (a, b) => {
    // we need to use custom sorting algorithm to meet our requirements.
    // parseInt(a.id, 10) - parseInt(b.id, 10);
    const aPos = a.pos.toUpperCase();
    const bPos = b.pos.toUpperCase();
    const aValue = aPos.charCodeAt(0) * 100 + parseInt(aPos.slice(1), 10);
    const bValue = bPos.charCodeAt(0) * 100 + parseInt(bPos.slice(1), 10);
    return aValue - bValue;
  };

  checkVersionUpdate() {
    const { machineStatus } = { ...this.props };
    // Check for software version update
    if (machineStatus === MACHINE_STATUS.OFFLINE) {
      return false;
    }
    // console.log('Checking Update available after vending');
    return checkUpdate().then(data => {
      if (!data.error && data.data.update_available) {
        window.location.reload(true); // Hard Refresh if a new version is available
      }
      return true;
    });
  }

  changeSelectedProduct(index) {
    const { addClickToLog } = { ...this.props };
    addClickToLog(`change selected product`);
    this.setState({ selectedProductIndex: index });
  }

  checkRefillRequest() {
    // Check Refill Open Request
    const { history } = { ...this.props };

    refillStateCheck().then(data => {
      window.heap.track('Check refill request', {
        time: Date.now(),
      });
      if (!data.error && data.success) {
        updateMachineStatus({ statusCode: 42, message: 'Opened for refill', mode: 'interaction' });
        history.push('/refill');
      }
    });
  }

  checkout() {
    const { openQRModal } = { ...this.props };

    window.heap.track('Paybutton clicked', {
      time: Date.now(),
    });

    // Make an Order Request. On Pay Now.

    // this.setState({
    //   showPopoverForCartFull: false,
    // });
    openQRModal(true);
    if (this.idleTimer) {
      this.idleTimer.pause();
    }
    return {};
  }

  // startScreenSaverTimmer() {
  // const { history, screenSaverIsActive } = { ...this.props };
  // window.customClearInterval(this.screenSaverTimmer, TIMER.SCREEN_SAVER_TIMMER);
  // this.screenSaverTimmer = window.customSetInterval(
  //   () => {
  //     const { isOpenQRModal, isOpenVendModal, isOpenPaymentTimedOutModal, totalItems } = {
  //       ...this.props,
  //     };

  //     if (
  //       !isOpenQRModal &&
  //       !isOpenVendModal &&
  //       !isOpenPaymentTimedOutModal &&
  //       localStorage.getItem('screensaverLink') && typeof localStorage.getItem('screensaverLink') !== 'object'
  //     ) {
  //       if (totalItems <= 0) {
  //         screenSaverIsActive();
  //       }
  //       history.push('/Screensaver');
  //     }
  //   },
  //   1 * 60000 * 1 * 15, // for testing
  //   TIMER.SCREEN_SAVER_TIMMER,
  // );
  // }

  async updateCart(id, updateBy, possitionGivenByRowVersion, showPos) {
    // set screen saver timmer again
    const { productsWithCartQuantity, machineType, updateCart, disableSelect, maxItems } = {
      ...this.props,
    };
    // console.log('Updating cart by', id, updateBy, possitionGivenByRowVersion);
    let { cartData } = { ...this.props };
    // this.startScreenSaverTimmer(); // Reset and start Screensaver timer on every update to cart
    const productIndex = productsWithCartQuantity.findIndex(productItem => productItem.id === id);
    // console.log('cartData', cartData);
    // console.log('productIndex', productIndex);
    // console.log('pos to add ', possitionGivenByRowVersion);
    const selectedProduct = productsWithCartQuantity[productIndex];

    if (maxItems === 1) {
      const cart = [
        {
          id,
          product_id: selectedProduct.product_id,
          product_mini_image: selectedProduct.image_mini,
          name: selectedProduct.name,
          quantity: updateBy,
          showPos: selectedProduct.show_pos,
          price: selectedProduct.price,
          pos: possitionGivenByRowVersion || selectedProduct.pos,
          description: selectedProduct.description,
        },
      ];

      disableSelect(true);
      updateCart({
        cartData: cart,
        totalAmount: parseFloat(selectedProduct.price),
        totalItems: 1,
      });
      return this.checkout();
    }
    // possitionGivenByRowVersion ||
    const cartIndex = cartData.findIndex(cartItem => cartItem.id === id);
    const cartPosIndex = cartData.findIndex(cartItem => cartItem.pos === possitionGivenByRowVersion);
    // console.log('updateBy', updateBy);
    // console.log('cartIndex', cartIndex);
    if (machineType === 'rowproductselect' || machineType === 'selectionbyproductid') {
      if (updateBy <= 0 && cartIndex >= 0) {
        // console.log('cartData.pop', cartData);
        // console.log('cartData.pop', id);
        const productData = cartData.filter(item => item.id === id);
        cartData = cartData.filter(item => item.id !== id);
        const singleProduct = productData.pop();
        singleProduct.quantity -= 1;
        // console.log('productData.pop', productData);
        if (productData.length > 0) {
          productData.forEach(pData => cartData.push(pData));
        }
        // console.log('singleProduct.quantity', singleProduct.quantity);
        if (singleProduct.quantity > 0) {
          cartData.push(singleProduct);
          // console.log('CartDataAfter', cartData);
        }
      } else if (cartPosIndex === -1 && updateBy > 0) {
        // console.log('ADDING NEW ITEM TO CART', selectedProduct);
        cartData.push({
          id,
          product_id: selectedProduct.product_id,
          product_mini_image: selectedProduct.image_mini,
          name: selectedProduct.name,
          showPos: showPos || possitionGivenByRowVersion,
          description: selectedProduct.description,
          quantity: updateBy,
          price: selectedProduct.price,
          pos: possitionGivenByRowVersion,
        });
      } else if (cartPosIndex > -1) {
        // console.log('++ EXISTING item');
        cartData[cartPosIndex].quantity = updateBy;
      }
    } else if (cartIndex === -1 && updateBy > 0) {
      // if new type of product is added in cart
      cartData.push({
        id,
        product_id: selectedProduct.product_id,
        product_mini_image: selectedProduct.image_mini,
        name: selectedProduct.name,
        showPos: selectedProduct.show_pos,

        description: selectedProduct.description,
        quantity: updateBy,
        price: selectedProduct.price,
        pos: selectedProduct.pos,
      });
    } else if (cartIndex > -1) {
      // productsWithCartQuantity[productIndex].cart_quantity += updateBy;
      cartData[cartIndex].quantity = updateBy;

      if (cartData[cartIndex].quantity <= 0) {
        cartData.splice(cartIndex, 1); // When quantity becomes 0 remove the details from cart
      }
    }

    const itemsInCart = cartData.reduce((total, num) => {
      return total + num.quantity;
    }, 0);
    const cartPrice = cartData.reduce((total, num) => {
      return total + parseInt(num.quantity, 10) * parseFloat(num.price, 10);
    }, 0);
    // productsWithCartQuantity,
    if (itemsInCart < maxItems) {
      disableSelect(false);
      this.setState({
        showPopoverForCartFull: false,
      });
    } else if (itemsInCart >= maxItems) {
      disableSelect(true);

      this.setState({
        showPopoverForCartFull: true,
      });
    }
    // console.log('ItemsInCart', itemsInCart);
    // console.log('maxItems', maxItems);
    if (itemsInCart > 0) {
      this.setState({ isPopoverOpen: false });
    }
    // console.log('updating cart by', cartData);
    const { timeout } = this.state;
    this.setState({
      remainingTime: timeout,
      hasTimedOut: false, // reset the flag when an item is added
    });
    if (this.idleTimer) {
      this.idleTimer.reset();
    }

    updateCart({
      cartData,
      totalAmount: cartPrice,
      totalItems: itemsInCart,
    });
    return {};
  }

  handleResetCart() {
    const { resetCart } = { ...this.props };
    window.heap.track('Reset button clicked', {
      time: Date.now(),
    });
    this.setState({
      showPopoverForCartFull: false,
    });
    checkMultivac();
    this.checkRefillRequest(); // Check if refill request is submitted
    resetCart();
  }

  handleCloseCallback() {
    if (this.idleTimer) {
      this.idleTimer.reset();
    }
  }

  unsetPingInterval() {
    window.customClearInterval(this.pingInterval, 'ping'); // Stop Ping Interval
  }

  render() {
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    const {
      mSwipeConnected,
      selectedProductIndex,
      errorInComponent,
      isProductLoading,
      isErrorInProduct,
      isPopoverOpen,
      showPopoverForCartFull,
      className,
      buttonType,
    } = {
      ...this.state,
    };

    const {
      productsWithCartQuantity,
      history,
      isOpenQRModal,
      totalAmount,
      isOpenVendModal,
      isOpenPaymentTimedOutModal,
      machineType,
      resetCart,
      maxItems,
      isOpenPushRemainder,
      productDisplayPageUIOptions,
      buttonResetTxt,
      showFooterSubText,
      buttonPayTxt,
      txtSelectProductFirst,
      txtMaxItemAllowed,
    } = this.props;

    // console.log(showFooterSubText,"showFooterSubTextshowFooterSubText")
    const { timeout } = this.state;

    return (
      <div data-test="product-display-page" style={{ height: '100vh' }}>
        <IdleTimer
          startManually
          stopOnIdle
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={timeout}
          onIdle={this.onIdle}
        />
        {showPopoverForCartFull && (
          <Alert color="warning" data-testid="login-fail-message" className="fluid text-red ">
            {`${maxItems} ${txtMaxItemAllowed}`}
          </Alert>
        )}

        {isPopoverOpen && (
          <Alert color="warning" data-testid="login-fail-message" className="fluid text-red ">
            {txtSelectProductFirst}
          </Alert>
        )}

        {!errorInComponent && isOpenQRModal && (
          <Payment callback={this.handleCloseCallback} mSwipeConnected={mSwipeConnected} history={history} />
        )}
        {!errorInComponent && isOpenVendModal && (
          <VendModal resetCart={resetCart} checkUpdate={() => this.checkVersionUpdate()} />
        )}
        {!errorInComponent && isOpenPushRemainder && <PhoneNumberInput />}
        {isErrorInProduct && !isOpenQRModal && !isOpenVendModal && !isOpenPushRemainder && (
          <Error isErrorInProduct={isErrorInProduct} errorInComponent={errorInComponent} />
        )}
        {!errorInComponent && isOpenPaymentTimedOutModal && <TimedOutModal />}
        {machineType === 'metro' && <MetroTicketVending productsData={productsWithCartQuantity} />}
        {(machineType === 'big' ||
          machineType === 'imshow' ||
          machineType === 'selectionbyproductid' ||
          machineType === 'rowproductselect') && (
          <BigMachineUI
            productDisplayPage={productDisplayPageUIOptions}
            machineType={machineType}
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            isProductLoading={isProductLoading}
            maxItems={maxItems}
          />
        )}

        {machineType === 'newuiwithphoto' && (
          <NewUiWithPhoto
            productDisplayPage={productDisplayPageUIOptions}
            machineType={machineType}
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            isProductLoading={isProductLoading}
            maxItems={maxItems}
          />
        )}

        {machineType === 'newuiwithphotodubai' && (
          <NewUiWithPhotoDubai
            productDisplayPage={productDisplayPageUIOptions}
            machineType={machineType}
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            isProductLoading={isProductLoading}
            maxItems={maxItems}
          />
        )}

        {machineType === 'newuiwithoutphoto' && (
          <NewUiWithoutPhoto
            productDisplayPage={productDisplayPageUIOptions}
            machineType={machineType}
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            isProductLoading={isProductLoading}
            maxItems={maxItems}
          />
        )}

        {machineType === 'newuiwithoutphotov2' && (
          <NewUiWithoutPhotoV2
            productDisplayPage={productDisplayPageUIOptions}
            machineType={machineType}
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            isProductLoading={isProductLoading}
            maxItems={maxItems}
            showFooterSubText={showFooterSubText}
          />
        )}

        {machineType === 'newuiwithoutphotowithoutscroll' && (
          <NewUiWithoutPhotoWithoutScroll
            productDisplayPage={productDisplayPageUIOptions}
            machineType={machineType}
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            isProductLoading={isProductLoading}
            maxItems={maxItems}
          />
        )}

        {machineType === 'small' && (
          <SmallMachineUI
            updateCart={this.updateCart}
            productsData={productsWithCartQuantity}
            changeSelectedProduct={this.changeSelectedProduct}
            isProductLoading={isProductLoading}
            selectedProductIndex={selectedProductIndex}
          />
        )}
        {maxItems > 1 && machineType === 'big' && (
          <div className="product-display-buttton-container" id="Popover2">
            <div style={{ flexGrow: 1 }}>
              <Button
                data-test="reset-cart-button"
                className={`${
                  machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                    ? 'full-length-btn rowproduct-btn-light'
                    : 'wendor-big-button wendor-bg-red '
                }`}
                onClick={() => this.handleResetCart()}>
                {buttonResetTxt}
              </Button>
            </div>
            {totalAmount <= 0 && (
              <div style={{ flexGrow: 1 }}>
                <Button
                  className={`${
                    machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                      ? 'full-length-btn rowproduct-btn-dark'
                      : 'wendor-big-button wendor-bg-green '
                  }`}
                  type="button"
                  id="Popover1"
                  onClick={() => {
                    this.setState({ isPopoverOpen: true });
                  }}>{`${buttonPayTxt} ${currencyDetails.symbol}${totalAmount}`}</Button>
              </div>
            )}
            {totalAmount > 0 && (
              <div style={{ flexGrow: 1 }}>
                <Button
                  data-test="pay-button"
                  className={`${
                    machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                      ? 'full-length-btn rowproduct-btn-dark'
                      : 'wendor-big-button wendor-bg-green '
                  }`}
                  onClick={this.checkout}>
                  {buttonPayTxt}{' '}
                  <NumberFormat
                    value={` ${totalAmount} `}
                    displayType="text"
                    thousandSeparator
                    prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                    suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                  />
                </Button>
              </div>
            )}
          </div>
        )}
        {/* {maxItems > 1 && machineType !== 'newuiwithoutphoto' && (
          <div className="product-display-buttton-container" id="Popover2">
            <div style={{ flexGrow: 1 }}>
              <Button
                data-test="reset-cart-button"
                onTouchEnd={() => this.handleClickAnimation('reset')}
                className={`${buttonType === 'reset' ? className : ''} ${
                  machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                    ? 'full-length-btn rowproduct-btn-light'
                    : 'wendor-bg-red new-3d-button new-red-submit-btn reset-btn'
                }`}
                onClick={() => {
                  setTimeout(() => this.handleResetCart(), 100);
                }}>
                {buttonResetTxt}
              </Button>
            </div>
            {totalAmount <= 0 && (
              <div style={{ flexGrow: 1 }}>
                <Button
                  onTouchEnd={() => this.handleClickAnimation('pay')}
                  className={`${buttonType === 'pay' ? className : ''} ${
                    machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                      ? 'full-length-btn rowproduct-btn-dark'
                      : 'wendor-bg-green new-3d-button new-green-submit-btn pay-btn'
                  }`}
                  type="button"
                  id="Popover1"
                  onClick={() => {
                    this.setState({ isPopoverOpen: true });
                  }}>{`${buttonPayTxt} ${currencyDetails.symbol} ${totalAmount}`}</Button>
              </div>
            )}
            {totalAmount > 0 && (
              <div style={{ flexGrow: 1 }}>
                <Button
                  data-test="pay-button"
                  onTouchEnd={() => this.handleClickAnimation('pay')}
                  className={`${buttonType === 'pay' ? className : ''} ${
                    machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                      ? 'full-length-btn rowproduct-btn-dark'
                      : 'wendor-bg-green new-3d-button new-green-submit-btn pay-btn'
                  }`}
                  onClick={this.checkout}>
                  {buttonPayTxt}{' '}
                  <NumberFormat
                    value={` ${totalAmount} `}
                    displayType="text"
                    thousandSeparator
                    prefix={currencyDetails.isSuffix ? null : `${currencyDetails.symbol} `}
                    suffix={currencyDetails.isSuffix ? `${currencyDetails.symbol} ` : null}
                  />
                </Button>
              </div>
            )}
          </div>
        )} */}
        {maxItems > 1 &&
          (machineType === 'newuiwithoutphoto' ||
            machineType === 'newuiwithphoto' ||
            machineType === 'newuiwithphotodubai') && (
            <div className="product-display-buttton-container-new" id="Popover2">
              <div style={{ flexGrow: 1 }}>
                <Button
                  data-test="reset-cart-button"
                  onTouchEnd={() => this.handleClickAnimation('reset')}
                  className={`${buttonType === 'reset' ? className : ''} ${
                    machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                      ? 'full-length-btn rowproduct-btn-light'
                      : 'wendor-bg-red new-3d-button new-red-submit-btn reset-btn'
                  }`}
                  onClick={() => {
                    setTimeout(() => this.handleResetCart(), 100);
                  }}>
                  {buttonResetTxt}
                </Button>
              </div>
              {totalAmount <= 0 && (
                <div style={{ flexGrow: 1 }}>
                  <Button
                    onTouchEnd={() => this.handleClickAnimation('pay')}
                    className={`${buttonType === 'pay' ? className : ''} ${
                      machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                        ? 'full-length-btn rowproduct-btn-dark'
                        : 'wendor-bg-green new-3d-button new-green-submit-btn pay-btn'
                    }`}
                    type="button"
                    id="Popover1"
                    onClick={() => {
                      this.setState({ isPopoverOpen: true });
                    }}>{`${buttonPayTxt} ${currencyDetails.symbol} ${totalAmount}`}</Button>
                </div>
              )}
              {totalAmount > 0 && (
                <div style={{ flexGrow: 1 }}>
                  <Button
                    data-test="pay-button"
                    onTouchEnd={() => this.handleClickAnimation('pay')}
                    className={`${buttonType === 'pay' ? className : ''} ${
                      machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                        ? 'full-length-btn rowproduct-btn-dark'
                        : 'wendor-bg-green new-3d-button new-green-submit-btn pay-btn'
                    }`}
                    onClick={this.checkout}>
                    {buttonPayTxt}{' '}
                    <NumberFormat
                      value={` ${totalAmount} `}
                      displayType="text"
                      thousandSeparator
                      prefix={currencyDetails.isSuffix ? null : `${currencyDetails.symbol} `}
                      suffix={currencyDetails.isSuffix ? `${currencyDetails.symbol} ` : null}
                    />
                  </Button>
                </div>
              )}
            </div>
          )}

        {maxItems > 1 && machineType === 'newuiwithoutphotov2' && (
          <div style={{ overflow: 'hidden', width: '100%', position: 'absolute', bottom: 0 }}>
            <div className="product-display-buttton-container-new-v2 public-sans-font" id="Popover2">
              <div>
                <Button
                  data-test="reset-cart-button"
                  onTouchEnd={() => this.handleClickAnimation('reset')}
                  className={`${buttonType === 'reset' ? className : ''} ${
                    machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                      ? 'full-length-btn rowproduct-btn-light'
                      : 'wendor-bg-red new-3d-button-v2 new-red-submit-btn reset-btn-v2'
                  }`}
                  onClick={() => {
                    setTimeout(() => this.handleResetCart(), 100);
                  }}>
                  {buttonResetTxt}
                </Button>
              </div>
              {totalAmount <= 0 && (
                <div>
                  <Button
                    onTouchEnd={() => this.handleClickAnimation('pay')}
                    className={`${buttonType === 'pay' ? className : ''} ${
                      machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                        ? 'full-length-btn rowproduct-btn-dark'
                        : 'wendor-bg-green new-3d-button-v2 new-green-submit-btn pay-btn-v2'
                    }`}
                    type="button"
                    id="Popover1"
                    onClick={() => {
                      this.setState({ isPopoverOpen: true });
                    }}>{`${buttonPayTxt} ${currencyDetails.symbol} ${totalAmount}`}</Button>
                </div>
              )}
              {totalAmount > 0 && (
                <div>
                  <Button
                    data-test="pay-button"
                    onTouchEnd={() => this.handleClickAnimation('pay')}
                    className={`${buttonType === 'pay' ? className : ''} ${
                      machineType === 'rowproductselect' || machineType === 'selectionbyproductid'
                        ? 'full-length-btn rowproduct-btn-dark'
                        : 'wendor-bg-green new-3d-button-v2 new-green-submit-btn pay-btn-v2'
                    }`}
                    onClick={this.checkout}>
                    {buttonPayTxt}{' '}
                    <NumberFormat
                      value={` ${totalAmount} `}
                      displayType="text"
                      thousandSeparator
                      prefix={currencyDetails.isSuffix ? null : `${currencyDetails.symbol} `}
                      suffix={currencyDetails.isSuffix ? `${currencyDetails.symbol} ` : null}
                    />
                  </Button>
                </div>
              )}
            </div>
            {showFooterSubText && <Footer checkRefillMode={this.checkRefillRequest} />}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ machine, modalReducer, cartReducer, log }) => ({
  machineType: machine.machineType,
  maxItems: machine.machineDetails.maxItems,
  productDisplayPageUIOptions: machine.productDisplayPage,
  isOpenQRModal: modalReducer.isOpenQRModal,
  isOpenVendModal: modalReducer.isOpenVendModal,
  isOpenPushRemainder: modalReducer.isOpenPushRemainder,
  isOpenPaymentTimedOutModal: modalReducer.isOpenPaymentTimedOutModal,
  productsWithCartQuantity: cartReducer.productsWithCartQuantity,
  cartData: cartReducer.cartData,
  totalAmount: cartReducer.totalAmount,
  totalItems: cartReducer.totalItems,
  newCustomer: cartReducer.newCustomer,
  isScreenSaverActive: log.isScreenSaverActive,
  kioskUpdate: machine.kioskUpdate,
});

const mapDispatchToProps = dispatch => ({
  resetCart: () => dispatch({ type: CART_REDUCER.RESET_CART }),
  disableSelect: payload => dispatch({ type: CART_REDUCER.DISABLE_SELECT, payload }),
  resetModals: () => dispatch({ type: MODAL_REDUCER.RESET_MODALS }),
  updateCart: payload => dispatch({ type: CART_REDUCER.UPDATE_CART, payload }),
  openQRModal: payload => dispatch({ type: MODAL_REDUCER.OPEN_QR_MODAL, payload }),
  setProductsWithCartQuantity: payload => dispatch({ type: CART_REDUCER.SET_PRODUCTS_WITH_CART_QUANTITY, payload }),
  addClickToLog: payload => dispatch({ type: LOG.ADD_CLICK_TO_LOG, payload }),
  screenSaverIsActive: () => dispatch({ type: LOG.SCREEN_SAVER_WAS_ACTIVE }),
  screenSaverIsNotActive: () => dispatch({ type: LOG.SCREEN_SAVER_WAS_NOT_ACTIVE }),
  resetRequestData: () => dispatch({ type: REQUEST_REDUCER_TYPES.RESET_REQUEST_ID }),
  setKioskUpdate: payload => dispatch({ type: MACHINE.SET_KIOSK_UPDATE, payload }),
  refreshMachine: () => dispatch({ type: MACHINE.REFRESH_MACHINE }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsDisplay));

import axios from 'axios';
import { MACHINE_STATUS, MACHINE, MQTT_STATUS, ESP_STATUS } from '../../actions/constants';

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MACHINE.SET_LOGGED_IN:
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
      return {
        ...state,
        ...payload.machineSettings,
        loggedIn: true,
        machineStatus: MACHINE_STATUS.ONLINE,
      };
    case MACHINE.SET_LOGGED_OUT:
      axios.defaults.headers.common.Authorization = null;
      window.localStorage.clear();
      return {
        ...state,
        loggedIn: false,
        token: null,
        machineId: null,
        machineType: null,
      };
    case MACHINE.SET_MACHINE_OFFLINE:
      return {
        ...state,
        machineStatus: MACHINE_STATUS.OFFLINE,
        mqttStatus: MQTT_STATUS.DISCONNECTED,
        espStatus: ESP_STATUS.DISCONNECTED,
      };
    case MACHINE.SET_MACHINE_ONLINE:
      return {
        ...state,
        machineStatus: MACHINE_STATUS.ONLINE,
      };
    case MACHINE.MACHINE_TYPE_DID_NOT_MATCH:
      return {
        ...state,
        machineTypeDidNotMatch: true,
        openMachineTypeDidNotMatch: true,
      };
    case MACHINE.MACHINE_TYPE_DID_MATCH:
      return {
        ...state,
        machineTypeDidNotMatch: false,
        openMachineTypeDidNotMatch: false,
      };
    case MACHINE.CLOSE_MACHINE_TYPE_DID_NOT_MATCH_MODAL:
      return {
        ...state,
        openMachineTypeDidNotMatch: false,
      };
    case MACHINE.SET_PAYMENT_METHODS_FOR_MACHINE:
      return {
        ...state,
        enabledPaymentGateways: payload,
      };
    case MACHINE.REFRESH_MACHINE:
      return window.location.reload(); // Refresh if a new version is available
    case MACHINE.SET_MQTT_STATUS:
      return {
        ...state,
        mqttStatus: payload.status,
      };
    case MACHINE.SET_ESP_STATUS:
      return {
        ...state,
        espStatus: payload.status,
        isConfiguring: false,
      };
    case MACHINE.SET_IS_CONFIGURING:
      return {
        ...state,
        isConfiguring: payload.isConfiguring,
      };
    case MACHINE.SET_MACHINE_BALANCE:
      return {
        ...state,
        machineBalance: payload,
      };

    case MACHINE.SET_KIOSK_UPDATE:
      return {
        ...state,
        kioskUpdate: payload,
      };
    default:
      return state;
  }
};

export default reducer;
